<script>
import Layout from "../../layouts/main";
import * as cloneDeep from "lodash.clonedeep";
import * as moment from "moment";
import Multiselect from 'vue-multiselect';
import Filters from '../../../components/filters.vue';
import "vue2-datepicker/index.css";
import Breadcrumb from '../../../components/breadcrumb.vue';

import {
  FETCH_PAYMENT_PAGE_DETAILS,
  FETCH_PAYMENT_PAGE_TRANSACTIONS,
  FETCH_CHANNEL_LIST,
  EXPORT_PAYMENT_PAGE_TRANSACTIONS,
  GENERATE_QR_CODE
} from "@/state/actions.type";

import { UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE } from '@/state/mutations.type';
import DownloadBtn from "../../../components/download-btn.vue";
import PerPage from "../../../components/per-page.vue";
import SearchBy from "../../../components/search-by.vue";
export default {
  components: {
    Layout,
    Multiselect,
    DownloadBtn,
    PerPage,
    Filters,
    SearchBy,
    Breadcrumb
  },
  data() {
    return {
      // TODO: Fetch channel and method data by APIs
      paymentMethodList:['CARD','WALLET','BNPL','COD','NET_BANKING','BANK_TRANSFER','INSTALLMENT','QR_CODE','CRYPTO', 'SUBSCRIPTION_CARD', 'SUBSCRIPTION_DIRECT_DEBIT', 'ALL'],
      pageDetails: null,
      pageTransactions: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fromDate: "",
      toDate: "",
      filters: {
        pmt_channel:[],
        pmt_method:[],
        status:[],
      },
      merchant_uuids:[],
      searchBy: {
        key: "merchant_order_ref",
        value: ""
      },
      sorting_data: {
        "sort_by_key": "",
        "order": ""
      },
      searchByOptions: ['Merchant Order Ref', 'Channel Order Ref', 'PortOne Order Ref', 'Billing Name', 'Email', 'Phone Number'],
      totalRevenue: "",
      availableStockQuantity: 0,
      isStockEnabled: false,
      // isDetailsModalOpen: false,
      // selectedTrasactionData: null,
      choosenPaymentMethods: 0,
      fields: [
        // { key: "show_details", label: 'Options', tooltip: "Options" },
        { key: "created_at", sortable: true, label: "views.payment_pages.table.fields.txn_date", tooltip: "views.payment_pages.table.tooltip.txn_date" },
        { key: "buyer_name", sortable: true, label: "views.payment_pages.table.fields.buyer_name", tooltip: "views.payment_pages.table.tooltip.buyer_name" },
        { key: "merchant_order_ref", sortable: true, label: "views.payment_pages.table.fields.merchant_order_ref", tooltip: "views.payment_pages.table.tooltip.merchant_order_ref" },
        { key: "channel_key", sortable: true, label: "views.payment_pages.table.fields.channel_key", tooltip: "views.payment_pages.table.tooltip.channel_key" },
        { key: "method_name", sortable: true, label: "views.payment_pages.table.fields.method_name", tooltip: "views.payment_pages.table.tooltip.method_name" },
        { key: "order_ref", sortable: true, label: "views.payment_pages.table.fields.order_ref", tooltip: "views.payment_pages.table.tooltip.order_ref" },
        { key: "status", sortable: true, label: "views.payment_pages.table.fields.status_details", tooltip: "views.payment_pages.table.tooltip.status_details" },
        { key: "amount", sortable: true, label: "views.payment_pages.table.fields.amount", tooltip: "views.payment_pages.table.tooltip.amount" },
        { key: "merchant_email_address", sortable: true, label: "views.payment_pages.table.fields.merchant_email_address", thClass: "",tdClass: "" },
        { key: "order_ref", sortable: true, label: "PortOne Order ID", tooltip: "" },
        { key: "status_code", sortable: true, label: "views.payment_pages.table.fields.status_code", tooltip: "views.payment_pages.table.tooltip.status_code" },
        { key: "status_channel_reason", sortable: true, label: "views.payment_pages.table.fields.status_channel_reason", tooltip: "views.payment_pages.table.tooltip.status_channel_reason" },
        { key: "payment_method_used", sortable: true, label: "views.payment_pages.table.fields.payment_method_used", tooltip: "views.payment_pages.table.tooltip.payment_method_used" },
        { key: "channel_name", sortable: true, label: "views.payment_pages.table.fields.channel_name", tooltip: "views.payment_pages.table.tooltip.channel_name" },
      ],
    };
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    dateFormat(date) {
      return moment(date).format("dddd Do of MMMM YYYY hh:mm:ss a");
    }
  },

  created() {
    this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_DETAILS}`, {
      pageRef: this.$route.query.pageRef,
      submerchantKey: this.$route.query.key
    }).then(() => {
      this.pageDetails = cloneDeep(this.paymentPageDetailStore);
      this.availableStockQuantity = this.pageDetails.content.available_stock_quantity;
      this.isStockEnabled = this.pageDetails.content.is_stock_enabled;
      this.choosenPaymentMethods = Object.keys(this.pageDetails.content.chosen_payment_methods).length;
      // this.setTransactionData();
    });

    this.$store.dispatch(`transactions/${FETCH_CHANNEL_LIST}`)
  },

  computed: {
    paymentPageDetailStore() {
      return this.$store.state.paymentPages.pageDetails;
    },

    paymentPageTransactions() {
      return this.$store.state.paymentPages.pageTransactions;
    },

    isFetchingTransactions() {
      return this.$store.state.paymentPages.isFetchingData;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },

    paymentChannelList() {
      return this.$store.state.transactions.paymentChannelList.filter((value, index, array) => array.indexOf(value) === index);
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    items() {
      return [
        {
          text: this.$t('views.payment_pages.title'),
          active: false,
          href: "/settings/payment-pages"
        },
        {
          text: this.$t('views.payment_pages.details'),
          active: true,
        }
      ];
    },
  },
  watch: {
    isStockEnabled(value) {
      if(!value) {
        this.availableStockQuantity = 0;
      }
    },
  },

  methods: {
    resetAdvanceFilters() {
      this.filters = {
        pmt_channel:[],
        pmt_method:[],
        status:[],
      };

      this.setTransactionData();
    },

    toggleFilter() {
      this.$root.$emit('bv::toggle::collapse', 'transaction-filter-collapse');
    },

    dateChanged(value) {
      this.fromDate = value[0];
      this.toDate = value[1];

      this.setTransactionData();
    },

    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_pages.copy_success'),
        closeOnClick: true,
      });
    },

    onCopyQrSuccess() {
      this.$notify({
        type: "success",
        text: "QR Code Copied Successfully",
        closeOnClick: true,
      });
    },
    downloadQR() {
      const link = document.createElement('a');
      link.href = this.pageDetails.content.qrcode_link;
      link.download = 'QRCode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    editPaymentPage() {
      this.$router.push({ path: '/settings/payment-pages/config', query: { pageRef: this.pageDetails.payment_link_details.payment_page_ref, key: this.isMasterMerchant ? this.pageDetails.content.chaipay_key : '' }})
    },

    sortTransactions(item) {
      this.sorting_data.sort_by_key = item.sortBy;

      if(item.sortDesc) {
        this.sorting_data.order = "DESC";
      } else {
        this.sorting_data.order = "ASC";
      }

      this.setTransactionData();
    },

    getSearchByKey(value) {
      let key = "";
      switch (value) {
        case 'Merchant Order Ref':
          key = 'merchant_order_ref';
          break;
        case 'Channel Order Ref':
          key = 'channel_order_ref';
          break;
        case 'PortOne Order Ref':
          key = 'order_ref';
          break;
        case 'Billing Name':
          key = 'buyer_name';
          break;
        case 'Email':
          key = 'buyer_email';
          break;
        case 'Phone Number':
          key = 'buyer_phone';
          break;
        default:
          break;
      }
      return key;
    },

    getPayload() {
      const filters = cloneDeep(this.filters);

      if(this.searchBy.key && this.searchBy.value) {
        filters[this.getSearchByKey(this.searchBy.key)] = this.searchBy.value;
      } else {
        this.searchBy.value = "";
      }

      const payload = {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: filters,
        sorting_data: this.sorting_data
      };

      if(this.fromDate) {
        payload.from = moment(this.fromDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        payload.from = moment('2018-01-01T00:00:00.000').format("YYYY-MM-DDTHH:mm:ssZ");
      }

      if(this.toDate) {
        payload.to = moment(this.toDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        payload.to = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      }


      if(this.isMasterMerchant) {
        payload.merchant_keys = this.merchant_uuids.map(function (item) {
          return item.key;
        });
      }

      return payload;
    },

    setTransactionData() {

      this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_TRANSACTIONS}`, {
        payload: this.getPayload(),
        pageRef: this.pageDetails.payment_link_details.payment_page_ref
      }).then(() => {
        let result = this.paymentPageTransactions;
        var pageTransactionsData = cloneDeep(result.transactions.content) 
        const pageTransactions_content = pageTransactionsData.map((pageTrs) => {
          var charge_params = {}
          var dynamic_column = [];
          if(pageTrs.custom_page_fields){ 
            for (var i in pageTrs.custom_page_fields) {
              var keyname = `${i}`
              var keyvalue = `${pageTrs.custom_page_fields[i] ? pageTrs.custom_page_fields[i] : "-"}`
              charge_params[keyname] = keyvalue
              dynamic_column.push(keyname);
            }            
          }
          var pageTrsstaticData = {
            dynamic_column:dynamic_column,
          };
          var pageTrsstaticDynamicData = {  ...pageTrs, ...pageTrsstaticData, ...charge_params };
          return pageTrsstaticDynamicData
        });
        var dynamic_field = []
        dynamic_field = cloneDeep(this.fields);
          if(pageTransactions_content.length > 0){
            if(pageTransactions_content[0].dynamic_column.length > 0) {
              pageTransactions_content[0].dynamic_column.map(function (item) {
                return dynamic_field.push({ key: item, sortable: true, label: item });
              });
            }
            this.fields = dynamic_field 
          }  
        this.pageTransactions = pageTransactions_content
        this.totalRows = result.transactions.total_elements;
        this.totalRevenue = result.total_amount;
      });
    },

    getlangcodefromcurr(currdata) {
      let language;
      if (currdata) {
        if (currdata === "VND") {
          language = "vi";
        } else if (currdata === "THB") {
          language = "th";
        } else {
          language = "en";
        }
      } else {
        language = "vi";
      }
      return language;
    },

    getFormattedNumber(numberValue, currencyValue) {
      let choosenLanguage = this.getlangcodefromcurr(currencyValue);
      let formattedValue = new Intl.NumberFormat(choosenLanguage, {
        style: "currency",
        currency: currencyValue,
        minimumFractionDigits: 2,
      }).format(numberValue);
      return formattedValue;
    },

    downloadExcel() {
      this.$store.dispatch(`paymentPages/${EXPORT_PAYMENT_PAGE_TRANSACTIONS}`, {
        pageData: this.getPayload(),
        pageRef: this.$route.query.pageRef
      });
    },
    updateActiveStatus(status) {
      if(this.pageDetails.content.is_active !== status) {
        this.pageDetails.content.is_active = status;
  
        this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, {
          page_ref: this.$route.query.pageRef,
          is_active: status,
          available_stock_quantity: Number.parseInt(this.availableStockQuantity),
          is_stock_enabled: this.isStockEnabled
        });
      }
    },
    duplicatePage() {
      this.$router.push({ path: '/settings/payment-pages/config', query: { pageRef: this.pageDetails.payment_link_details.payment_page_ref, duplicate: true }})
    },
    updateStock() {
      if(this.availableStockQuantity < 0) {
        this.$notify({
          type: "error",
          text: 'Invalid Stock quantity.',
          closeOnClick: true
        });
        return;
      }
      this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, {
        page_ref: this.$route.query.pageRef,
        available_stock_quantity: Number.parseInt(this.availableStockQuantity),
        is_stock_enabled: this.isStockEnabled,
      }).then(() => {
        this.$notify({
          type: "success",
          text: this.$t('views.payment_pages.stock_updated_success'),
          closeOnClick: true
        });
        this.pageDetails.content.available_stock_quantity = this.availableStockQuantity;
        this.pageDetails.content.is_stock_enabled = this.isStockEnabled;
        this.$bvToast.hide('stock-toast');
      });
    },
    cancelStockUpdate() {
      this.availableStockQuantity = this.pageDetails.content.available_stock_quantity;
      this.isStockEnabled = this.pageDetails.content.is_stock_enabled;
      this.$bvToast.hide('stock-toast');
    },
    generateQR() {
      this.$store.dispatch(`paymentPages/${GENERATE_QR_CODE}`, {
        pageRef: this.$route.query.pageRef
      }).then((response) => {
        this.pageDetails.content.qrcode_link = response.QRCodeImage;
        this.$notify({
          type: "success",
          text: "QR Code Generated Successfully",
          closeOnClick: true,
        });
      }).catch((error) => {
        // error
        const err = JSON.parse(error.message);
        this.$notify({
          type: "error",
          text: err.message,
          closeOnClick: true,
        });
      });
    },
    // validateStock() {

    // }
  },

  // directives: {
  //   'click-outside': {
  //     bind (el, binding, vnode) {
  //       const handleOutsideClick = (e) => {
  //         e.stopPropagation()
  //         const { handler, exclude, toastId } = binding.value
  //         let clickedOnExcludedEl = false
  //         if (!clickedOnExcludedEl) {
  //           const excludedEl = vnode.context.$refs[exclude]
  //           if(Array.isArray(excludedEl)) {
  //             clickedOnExcludedEl = !excludedEl[0] || excludedEl[0].contains(e.target)
  //           } else {
  //             clickedOnExcludedEl = !excludedEl || excludedEl.contains(e.target)
  //           }
  //         }
  //         if (!el.contains(e.target) && !clickedOnExcludedEl) {
  //           if(toastId === "stock-toast") {
  //             if(vnode.context.validateStock()) {
  //               vnode.context[handler](toastId)
  //             }
  //           } else {
  //             vnode.context[handler](toastId)
  //           }
  //         }
  //       }
  //       document.addEventListener('click', handleOutsideClick)
  //       document.addEventListener('touchstart', handleOutsideClick)
  //     },
  //   }
  // },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <div class="d-flex justify-content-between mb-4">
      <breadcrumb :items="items" :goBackTo="{ name: 'Payment Pages' }" />

      <b-btn variant="primary" @click="$router.push({ path: '/settings/payment-pages/config', query: { pageRef: pageDetails.payment_link_details.payment_page_ref, key: isMasterMerchant ? pageDetails.content.chaipay_key : '' }})" style="font-size: 14px; font-weight: 400; line-height: 20px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M8.84006 2.39982L3.36673 8.19315C3.16006 8.41315 2.96006 8.84649 2.92006 9.14649L2.6734 11.3065C2.58673 12.0865 3.14673 12.6198 3.92006 12.4865L6.06673 12.1198C6.36673 12.0665 6.78673 11.8465 6.9934 11.6198L12.4667 5.82649C13.4134 4.82649 13.8401 3.68649 12.3667 2.29315C10.9001 0.913152 9.78673 1.39982 8.84006 2.39982Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.92676 3.36621C8.21342 5.20621 9.70676 6.61288 11.5601 6.79954" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2 14.667H14" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Edit Page
      </b-btn>
    </div>

    <div v-if="pageDetails">
      <div class="p-4 page-detail-bg" style="border-radius: 12px; border: 1px solid rgba(220, 218, 213, 0.50); box-shadow: 0px 2px 8px 0px rgba(220, 218, 213, 0.30); margin-bottom: 32px;">
        <div class="d-flex justify-content-between">
          <div>
            <p class="mb-2 font-12px" style="color: rgba(0, 0, 0, 0.50); font-weight: 350; line-height: normal;">Payment Page</p>
    
            <div style="gap: 12px;" class="d-flex">
              <div v-if="pageDetails.content.merchant_details.logo" class="d-flex" style="height: 51px; width: 52px;">
                <img :src="pageDetails.content.merchant_details.logo" alt="brand logo" class="mx-auto my-auto">
              </div>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="52" height="51" viewBox="0 0 52 51" fill="none">
                <g filter="url(#filter0_d_3847_39897)">
                  <rect x="4.9176" y="2" width="42.1667" height="42.1667" rx="10" fill="black"/>
                </g>
                <g filter="url(#filter1_d_3847_39897)">
                  <path d="M47.0843 28.8337V19.2503C47.0843 9.66698 42.8676 5.83365 32.3259 5.83365H19.6759C9.13427 5.83365 4.9176 9.66698 4.9176 19.2503L4.9176 30.7503C4.9176 40.3337 9.13427 44.167 19.6759 44.167H30.2176" fill="white"/>
                </g>
                <path d="M16.4176 23.083H27.9176" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.4176 15.417L24.0843 15.417" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M26 19.25H29.8333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.4176 28.833H18.3343" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M27.9176 28.833H35.5843" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.25 34.583H22.1667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M26 34.583H33.6667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M38.65 16.9496C34.05 16.9496 34.05 18.9736 34.05 21.5496C34.05 16.9496 32.394 16.9496 29.45 16.9496C34.05 16.9496 34.05 13.1776 34.05 12.3496C34.05 16.9496 37.96 16.9663 38.65 16.9496Z" fill="black"/>
                <g filter="url(#filter2_ii_3847_39897)">
                  <path d="M47.04 28.8712C47.0405 24.9994 39.4169 25.9575 39.4169 25.9575C33.6669 27.9128 33.6668 31.7077 32.7085 36.4993C32.7085 36.4993 32.1752 42.4242 30.2584 44.1281C28.3415 45.8321 47.0394 32.7431 47.04 28.8712Z" fill="url(#paint0_diamond_3847_39897)"/>
                </g>
                <defs>
                  <filter id="filter0_d_3847_39897" x="0.917603" y="0" width="50.1667" height="50.167" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3847_39897"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3847_39897" result="shape"/>
                  </filter>
                  <filter id="filter1_d_3847_39897" x="2.9176" y="4.83398" width="46.1667" height="42.333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3847_39897"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3847_39897" result="shape"/>
                  </filter>
                  <filter id="filter2_ii_3847_39897" x="30.1205" y="25.8525" width="16.9195" height="18.4277" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.865739 0 0 0 0 0.865739 0 0 0 0 0.865739 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3847_39897"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="-1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.714388 0 0 0 0 0.714388 0 0 0 0 0.714388 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="effect1_innerShadow_3847_39897" result="effect2_innerShadow_3847_39897"/>
                  </filter>
                  <radialGradient id="paint0_diamond_3847_39897" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.4174 32.6661) rotate(144.462) scale(8.2439 8.47568)">
                    <stop stop-color="#E6E6E6"/>
                    <stop offset="1" stop-color="#DFDFDF"/>
                  </radialGradient>
                </defs>
              </svg>
    
              <div>
                <h1 class="mb-2" style="color: #000; font-size: 20px; font-style: normal; font-weight: 400; line-height: 24px;">{{ pageDetails.content.title }}</h1>
                <p class="font-12px mb-0"><span style="color: rgba(0, 0, 0, 0.50);">Created by:</span> {{ pageDetails.content.merchant_details.email_address }}</p>
              </div>
            </div>
    
            <div class="d-flex" style="margin-top: 12px;">
              <span class="font-12px my-auto mr-1">Page URL:</span>
              <div class="text-chai" style="border-radius: 12px; border: 1px solid #DCDAD5; background: #FFF; padding: 12px 16px 12px 13px; width: 434px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                <a class="text-chai" style="line-height: 18px;" :href="pageDetails.content.page_link" target="_blank">{{ pageDetails.content.page_link }}</a>
              </div>
              <b-btn variant="primary" style="cursor: copy; margin-left: 12px;" v-clipboard:copy="pageDetails ? pageDetails.content.page_link : ''" v-clipboard:success="onCopySuccess">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g clip-path="url(#clip0_3847_39881)">
                    <path d="M10.6667 8.59967V11.3997C10.6667 13.733 9.73334 14.6663 7.40001 14.6663H4.60001C2.26668 14.6663 1.33334 13.733 1.33334 11.3997V8.59967C1.33334 6.26634 2.26668 5.33301 4.60001 5.33301H7.40001C9.73334 5.33301 10.6667 6.26634 10.6667 8.59967Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.6667 4.59967V7.39967C14.6667 9.73301 13.7333 10.6663 11.4 10.6663H10.6667V8.59967C10.6667 6.26634 9.73334 5.33301 7.40001 5.33301H5.33334V4.59967C5.33334 2.26634 6.26668 1.33301 8.60001 1.33301H11.4C13.7333 1.33301 14.6667 2.26634 14.6667 4.59967Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_3847_39881">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                {{ $t("common.copy") }}
              </b-btn>
    
              <b-dropdown variant="share" size="sm" class="btn-share mx-2">
                <template v-slot:button-content>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M12 5.33301C13.1046 5.33301 14 4.43758 14 3.33301C14 2.22844 13.1046 1.33301 12 1.33301C10.8954 1.33301 10 2.22844 10 3.33301C10 4.43758 10.8954 5.33301 12 5.33301Z" stroke="black" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 14.667C13.1046 14.667 14 13.7716 14 12.667C14 11.5624 13.1046 10.667 12 10.667C10.8954 10.667 10 11.5624 10 12.667C10 13.7716 10.8954 14.667 12 14.667Z" stroke="black" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.72665 9.00684L10.28 11.6602" stroke="black" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.2733 4.33984L5.72665 6.99318" stroke="black" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  {{ $t('views.payment_pages.share') }}
                </template>
                <b-dropdown-item-button>
                  <ShareNetwork
                    network="facebook"
                    :url="pageDetails.content.page_link"
                    :title="pageDetails.content.title"
                  >
                    {{ $t('views.payment_pages.share_fb') }}
                  </ShareNetwork>
                </b-dropdown-item-button>
                <b-dropdown-item-button>
                  <ShareNetwork
                    network="whatsapp"
                    :url="pageDetails.content.page_link"
                    :title="pageDetails.content.title"
                  >
                    {{ $t('views.payment_pages.share_wa') }}
                  </ShareNetwork>
                </b-dropdown-item-button>
              </b-dropdown>
    
              <b-btn variant="share" class="mr-2" @click="duplicatePage" v-if="!isMasterMerchant">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g opacity="0.7" clip-path="url(#clip0_3847_39864)">
                    <path opacity="0.7" d="M8.59334 3.91992H3.40668C2.85734 3.92168 2.331 4.14068 1.94255 4.52913C1.55411 4.91757 1.3351 5.44391 1.33334 5.99326V13.5666C1.33334 14.5333 2.02668 14.9466 2.87334 14.4733L5.49334 13.0133C5.77334 12.8599 6.22668 12.8599 6.50001 13.0133L9.12001 14.4733C9.96668 14.9466 10.66 14.5333 10.66 13.5666V5.99326C10.6604 5.72142 10.6073 5.45216 10.5037 5.20085C10.4001 4.94953 10.248 4.72109 10.0561 4.52857C9.86417 4.33604 9.63622 4.1832 9.38525 4.07877C9.13427 3.97433 8.86518 3.92036 8.59334 3.91992Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path opacity="0.7" d="M10.6667 5.99326V13.5666C10.6667 14.5333 9.97334 14.9399 9.12668 14.4733L6.50668 13.0133C6.22668 12.8599 5.77334 12.8599 5.49334 13.0133L2.87334 14.4733C2.02668 14.9399 1.33334 14.5333 1.33334 13.5666V5.99326C1.33334 4.85325 2.26668 3.91992 3.40668 3.91992H8.59334C9.73334 3.91992 10.6667 4.85325 10.6667 5.99326Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.6667 3.40634V10.9797C14.6667 11.9463 13.9733 12.353 13.1267 11.8863L10.6667 10.513V5.99301C10.6667 4.85301 9.73334 3.91967 8.59334 3.91967H5.33334V3.40634C5.33334 2.26634 6.26668 1.33301 7.40668 1.33301H12.5933C13.7333 1.33301 14.6667 2.26634 14.6667 3.40634Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_3847_39864">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                {{ $t('views.payment_pages.duplicate') }}
              </b-btn>
            </div>
          </div>

          <b-btn variant="share" class="ml-auto mt-auto" style="height: 44px;" @click="generateQR" v-if="!pageDetails.content.qrcode_link">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g opacity="0.7" clip-path="url(#clip0_3847_39891)">
                <path opacity="0.7" d="M1.33331 5.99967V4.33301C1.33331 2.67301 2.67331 1.33301 4.33331 1.33301H5.99998M9.99998 1.33301H11.6666C13.3266 1.33301 14.6666 2.67301 14.6666 4.33301V5.99967M14.6666 10.6663V11.6663C14.6666 13.3263 13.3266 14.6663 11.6666 14.6663H10.6666M5.99998 14.6663H4.33331C2.67331 14.6663 1.33331 13.3263 1.33331 11.6663V9.99967M6.99998 4.66634V5.99967C6.99998 6.66634 6.66665 6.99967 5.99998 6.99967H4.66665C3.99998 6.99967 3.66665 6.66634 3.66665 5.99967V4.66634C3.66665 3.99967 3.99998 3.66634 4.66665 3.66634H5.99998C6.66665 3.66634 6.99998 3.99967 6.99998 4.66634ZM12.3333 4.66634V5.99967C12.3333 6.66634 12 6.99967 11.3333 6.99967H9.99998C9.33331 6.99967 8.99998 6.66634 8.99998 5.99967V4.66634C8.99998 3.99967 9.33331 3.66634 9.99998 3.66634H11.3333C12 3.66634 12.3333 3.99967 12.3333 4.66634ZM6.99998 9.99967V11.333C6.99998 11.9997 6.66665 12.333 5.99998 12.333H4.66665C3.99998 12.333 3.66665 11.9997 3.66665 11.333V9.99967C3.66665 9.33301 3.99998 8.99967 4.66665 8.99967H5.99998C6.66665 8.99967 6.99998 9.33301 6.99998 9.99967ZM12.3333 9.99967V11.333C12.3333 11.9997 12 12.333 11.3333 12.333H9.99998C9.33331 12.333 8.99998 11.9997 8.99998 11.333V9.99967C8.99998 9.33301 9.33331 8.99967 9.99998 8.99967H11.3333C12 8.99967 12.3333 9.33301 12.3333 9.99967Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_3847_39891">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Generate QR
          </b-btn>
          <div v-else>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none" style="position: absolute; margin-left: -5px; margin-top: -6px;">
                <path d="M23.7871 1L7.99993 1C4.13394 1 0.999933 4.13401 0.999933 8L0.999933 20.1621" stroke="#FC6B2D"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none" style="position: absolute; margin-left: -5px; margin-top: 65px;">
                <path d="M22.7871 19L7.78711 19C3.92112 19 0.78711 15.866 0.78711 12L0.78711 2.64683e-07" stroke="#FC6B2D"/>
              </svg>

              <img :src="pageDetails.content.qrcode_link" height="80px" width="80px" alt="">

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none" style="position: absolute; margin-left: -17px; margin-top: -6px;">
                <path d="M0.215332 1L16.0025 1C19.8685 1 23.0025 4.13401 23.0025 8L23.0025 20.1621" stroke="#FC6B2D"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none" style="position: absolute; margin-left: -17px; margin-top: 65px;">
                <path d="M0.215332 19L15.2153 19C19.0813 19 22.2153 15.866 22.2153 12L22.2153 -8.58694e-08" stroke="#FC6B2D"/>
              </svg>
            </div>
            <div style="margin-top: 12px;">
              <b-btn size="sm" variant="primary" v-clipboard:copy="pageDetails.content.qrcode_link" v-clipboard:success="onCopyQrSuccess" v-b-tooltip.hover.bottom title="copy" class="px-2 py-1" style="cursor: copy;">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <g clip-path="url(#clip0_3847_41460)">
                    <path d="M8 6.45L8 8.55C8 10.3 7.3 11 5.55 11L3.45 11C1.7 11 1 10.3 1 8.55L1 6.45C1 4.7 1.7 4 3.45 4L5.55 4C7.3 4 8 4.7 8 6.45Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 3.45L11 5.55C11 7.3 10.3 8 8.55 8L8 8L8 6.45C8 4.7 7.3 4 5.55 4L4 4L4 3.45C4 1.7 4.7 1 6.45 1L8.55 1C10.3 1 11 1.7 11 3.45Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_3847_41460">
                      <rect width="12" height="12" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </b-btn>
              <a class="btn btn-primary btn-sm float-right px-2 py-1" :href="pageDetails.content.qrcode_link" v-b-tooltip.hover.bottom title="download">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <g clip-path="url(#clip0_3847_41463)">
                    <path d="M8.21999 4.45C10.02 4.605 10.755 5.53 10.755 7.555L10.755 7.62C10.755 9.855 9.85999 10.75 7.62499 10.75L4.36999 10.75C2.13499 10.75 1.23999 9.855 1.23999 7.62L1.23999 7.555C1.23999 5.545 1.96499 4.62 3.73499 4.455M5.99999 1L5.99999 7.44" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.67495 6.3252L5.99995 8.0002L4.32495 6.3252" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_3847_41463">
                      <rect width="12" height="12" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div style="font-size: 14px;">
          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-bottom: 12px;">
    
          <div class="d-flex" style="line-height: 20px;">
            <div style="width: 280px; color: rgba(0, 0, 0, 0.50);" class="my-auto">Payment Page ID</div>
            <div class="my-auto">{{ $route.query.pageRef }}</div>
            <div
              v-clipboard:copy="$route.query.pageRef"
              v-clipboard:success="() => $notify({
                type: 'success',
                text: 'Payment page ID copied to clipboard.',
                closeOnClick: true,
              })"
              class="ml-2"
              style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); cursor: copy; padding: 6px; height: 32px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <g opacity="0.3" clip-path="url(#clip0_3847_40279)">
                  <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_3847_40279">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-bottom: 12px; margin-top: 12px;">

          <div class="d-flex" style="line-height: 20px;">
            <div style="width: 280px; color: rgba(0, 0, 0, 0.50);" class="my-auto">{{ $t('views.payment_pages.page_status') }}</div>

            <b-btn variant="link" @click="updateActiveStatus(true)" v-if="!pageDetails.content.is_active" class="mr-2">
              Activate
            </b-btn>
            <span class="badge-active" style="height: 32px; border-radius: 12px;" v-if="pageDetails.content.is_active">{{ $t("accounts.active") }}</span>
            <span class="badge-inactive" style="height: 32px; border-radius: 12px;" v-else>{{ $t("accounts.inactive") }}</span>
            
            <b-btn variant="link" @click="updateActiveStatus(false)" class="font-12px ml-2" style="color: #DE3535;" v-if="pageDetails.content.is_active">
              {{ $t('views.payment_pages.deactivate') }}
            </b-btn>
          </div>

          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-bottom: 12px; margin-top: 12px;">

          <div class="d-flex" style="line-height: 20px;">
            <div style="width: 280px; color: rgba(0, 0, 0, 0.50);" class="my-auto">{{ $t('views.payment_pages.created_on') }}</div>
            <span>{{ pageDetails.content.created_at | dateFormat }}</span>
          </div>

          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-bottom: 8px; margin-top: 12px;">
        </div>

        <div style="border-radius: 12px; border: 1px solid rgba(220, 218, 213, 0.50); background: rgba(220, 218, 213, 0.30); padding: 16px 24px;">
          <div class="d-flex justify-content-between">
            <div style="min-width: 150px; border-right: 2px solid rgba(220, 218, 213, 0.50);">
              <h5 class="text-chai font-16px" style="font-weight: 400; line-height: 24px; margin-bottom: 12px;">{{ $t('views.payment_pages.revenue') }}</h5>
              <h6 class="mb-0" style="font-size: 18px; font-weight: 350; line-height: 24px;">{{ getFormattedNumber(totalRevenue, pageDetails.content.currency) }}</h6>
            </div>

            <div>
              <h5 style="font-size: 14px; font-weight: 350; line-height: 24px; margin-bottom: 12px;">{{ $t('common.amount') }}</h5>
              <h6 class="mb-0" style="font-size: 18px; font-weight: 350; line-height: 24px;">{{ getFormattedNumber(pageDetails.content.amount, pageDetails.content.currency) }}</h6>
            </div>

            <div>
              <h5 style="font-size: 14px; font-weight: 350; line-height: 24px; margin-bottom: 12px;">{{ $t('views.payment_pages.unit_sold') }}</h5>
              <h6 class="mb-0" style="font-size: 18px; font-weight: 350; line-height: 24px;">{{ pageDetails.content.stock_sold_quantity }}</h6>
            </div>

            <div>
              <h5 style="font-size: 14px; font-weight: 350; line-height: 24px; margin-bottom: 12px;">{{ $t('common.transactions') }}</h5>
              <h6 class="mb-0" style="font-size: 18px; font-weight: 350; line-height: 24px;">{{ totalRows }}</h6>
            </div>

            <div>
              <h5 style="font-size: 14px; font-weight: 350; line-height: 24px; margin-bottom: 12px;">{{ $t('views.payment_pages.available_stock') }}</h5>
              <h6 class="mb-0" style="font-size: 18px; font-weight: 350; line-height: 24px;">{{ pageDetails.content.available_stock_quantity }} <span style="font-size: 16px; color: rgba(0, 0, 0, 0.50); font-weight: 325;">Units</span></h6>
            </div>

            <b-btn variant="link" class="text-chai mt-auto" @click="$bvToast.show('stock-toast')">Update Stock</b-btn>

              <!-- v-click-outside="{
                exclude: 'stock-toast',
                handler: 'hideToast',
                toastId: 'stock-toast'
              }" -->
            <b-toast
              id="stock-toast"
              no-close-button
              static
              backdrop-filter
              no-auto-hide
              style="position: absolute; z-index: 1050; right: 75px; margin-top: 61px;"
              class="bg-light"
              body-class="p-0"
            >
              <h1 class="m-3" style="font-size: 14px; font-weight: 350; line-height: 20px;">Available Stock</h1>

              <hr style="border-color: rgba(220, 218, 213, 0.50);" class="m-auto">

              <div class="px-3 pb-3">
                <b-checkbox
                  v-model="isStockEnabled"
                  :checked-value="true"
                  :unchecked-value="false"
                  class="my-2"
                >
                  <span class="font-12px" style="line-height: 16px;">{{ $t('views.payment_pages.limited_stocks') }}</span>
                </b-checkbox>
                <b-input class="my-2" style="border-radius: 12px; border: 1px solid #DCDAD5; padding: 10px 16px; height: 36px; font-size: 14px; line-height: 16px;" v-model.number="availableStockQuantity" :disabled="!isStockEnabled" :placeholder="$t('views.payment_pages.available_stock')"></b-input>

                <div class="d-flex justify-content-end">
                  <b-btn variant="link" class="px-1 py-0 font-12px" style="line-height: 16px;" @click="cancelStockUpdate">cancel</b-btn>
                  <b-btn variant="link" class="text-chai px-1 py-0 font-12px" style="line-height: 16px;" @click="updateStock">save</b-btn>
                </div>
              </div>
            </b-toast>
          </div>
        </div>

      </div>

      <!-- <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="mx-1 mb-4 row rounded" v-if="pageDetails" style="border: 1px dashed #e3e4e5 !important;font-size: 13px;">
              <div class="col-md-6 p-3">
                <table class="page-details-1">
                  <colgroup>
                    <col width="30%">
                    <col>
                  </colgroup>
                  <tr>
                    <td>{{ $t('views.payment_pages.page_url') }}:</td>
                    <td>
                      <div class="d-flex justify-content-between">
                        <p class="text-truncate mb-0 my-auto" style="width: 13rem;">
                          <a :href="pageDetails.content.page_link" target="_blank">{{ pageDetails.content.page_link }}</a>
                        </p>
                        <b-btn size="sm" variant="outline-primary" style="" v-clipboard:copy="pageDetails ? pageDetails.content.page_link : ''" v-clipboard:success="onCopySuccess">
                          <i class="mdi mdi-content-copy"></i>{{ $t("common.copy") }}
                        </b-btn>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('views.payment_pages.page_status') }}:</td>
                    <td>
                      <b-btn
                        variant="primary"
                        @click="updateActiveStatus(true)"
                        class="mr-2"
                        style="font-size: 12px; background: #fff;"
                        :style="[ !pageDetails.content.is_active ? `border: 1px solid #DAD9D9; color: #D9D9D9;` : `border: 1px solid #252B3A; color: #252B3A;`]"
                        size="sm"
                      >
                        <div class="checkbox-group">
                          <label class="position-relative mb-0" :class="pageDetails.content.is_active ? `filled` : ``">{{ $t('views.payment_pages.activate') }}</label>
                        </div>
                      </b-btn>
                      <b-btn
                        variant="primary"
                        @click="updateActiveStatus(false)"
                        style="font-size: 12px; background: #fff;"
                        :style="[ pageDetails.content.is_active ? `border: 1px solid #DAD9D9; color: #D9D9D9;` : `border: 1px solid #252B3A; color: #252B3A;`]"
                        size="sm"
                      >
                        <div class="checkbox-group">
                          <label class="position-relative mb-0" :class="!pageDetails.content.is_active ? `filled` : ``">{{ $t('views.payment_pages.deactivate') }}</label>
                        </div>
                      </b-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('views.payment_pages.page_id') }}:</td>
                    <td>{{ pageDetails.payment_link_details.payment_page_ref }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('views.payment_pages.created_on') }}:</td>
                    <td>{{ pageDetails.content.created_at | dateFormat }}</td>
                  </tr>
                  <tr v-if="choosenPaymentMethods > 0">
                    <td>Payment Methods</td>
                    <td>
                      <b-btn class="m-0" size="sm" variant="primary" v-b-modal.payment-methods-view>
                        View
                      </b-btn>
                      
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-md-6 p-3 bg-light">
                <table class="page-details-2 w-100">
                  <colgroup>
                    <col width="30%">
                    <col width="50%">
                    <col width="20%">
                  </colgroup>
                  <tr>
                    <td>{{ $t('common.amount') }}:</td>
                    <td>{{ getFormattedNumber(pageDetails.content.amount, pageDetails.content.currency) }}</td>
                    <td rowspan="4">
                      <div v-if="pageDetails.content.qrcode_link">
                        <img :src="pageDetails.content.qrcode_link" class="w-100" alt="">
                        <b-btn size="sm" variant="primary" v-clipboard:copy="pageDetails.content.qrcode_link" v-clipboard:success="onCopyQrSuccess" v-b-tooltip.hover.bottom title="copy" class="p-0 px-3" style="font-size: 1rem; cursor: copy;">
                          <i
                            class="mdi mdi-content-copy"
                          ></i>
                        </b-btn>
                        <b-btn size="sm" variant="primary" class="p-0 px-3 float-right" style="font-size: 1rem;" v-b-tooltip.hover.bottom title="download" @click="downloadQR">
                          <i
                            class="mdi mdi-download"
                          ></i>
                        </b-btn>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('views.payment_pages.revenue') }}:</td>
                    <td><span class="badge badge-info">{{ getFormattedNumber(totalRevenue, pageDetails.content.currency) }}</span></td>
                  </tr>
                  <tr>
                    <td>{{ $t('views.payment_pages.unit_sold') }}:</td>
                    <td>
                      <div class="d-flex justify-centent-between">
                        <div class="my-auto">
                          <span class="badge badge-success">{{ pageDetails.content.stock_sold_quantity }}</span>
                          <span v-if="pageDetails.content.is_stock_enabled" class="ml-2">
                            {{ $t('views.payment_pages.of') }} <span class="ml-2 badge badge-primary">{{ Number(pageDetails.content.stock_sold_quantity) + Number(pageDetails.content.available_stock_quantity) }}</span>
                          </span>
                        </div>
                        <b-btn size="sm" variant="link" class="ml-auto mr-2 p-0 font-weight-bold" v-b-modal.update-stock-modal>
                          <i class="mdi mdi-pencil"></i>
                          {{ $t('views.payment_pages.update_stocks') }}
                        </b-btn>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('common.transactions') }}:</td>
                    <td>
                      <span class="badge badge-primary my-auto">{{ totalRows }}</span>
                    </td>
                  </tr>
                </table>
              </div>
              <b-modal id="payment-methods-view" v-if="choosenPaymentMethods > 0" no-close-on-backdrop hide-footer title="Payment Methods">
                  <div v-for="(pmtMethod, key, i) in pageDetails.content.chosen_payment_methods" :key="key">
                    <label class="text-primary mb-2">{{key}}</label>
                    <div v-for="(element, index) in pmtMethod" :key="index">
                      <b-img v-bind="{width: 40, height: 40, class: 'mx-2 my-1'}" :src="element.logo" rounded="circle" alt="Circle image"></b-img> <label class="text-primary my-auto">{{element.name}}</label>
                    </div>
                    <hr v-if="i !== (choosenPaymentMethods - 1)">
                  </div>
                </b-modal>
          </div>
        </div>
      </div>

      <b-modal id="update-stock-modal" :title="$t('views.payment_pages.update_stock')" ok-title="Save" cancel-variant="light" @ok="updateStock" centered>
        <b-form-checkbox
          v-model="isStockEnabled"
          class="mb-3"
        >
          {{ $t('views.payment_pages.limited_stock') }}
        </b-form-checkbox>
        <b-form-input v-model="availableStockQuantity" :disabled="!isStockEnabled" :placeholder="$t('views.payment_pages.available_stock')"></b-form-input>
      </b-modal> -->

      <div class="d-flex justify-content-between">
        <div>
          <h1 class="mb-0" style="font-size: 18px; color: #000; font-style: normal; font-weight: 350; line-height: 24px;">Transactions</h1>
          <p class="font-12px mb-0" style="font-weight: 325; line-height: 16px; color: rgba(0, 0, 0, 0.50);">Below are the <span class="text-chai">transactions</span> for the selected payment page.</p>
        </div>
        <download-btn @download-button-clicked="downloadExcel" />
      </div>

      <hr style="border-color: rgba(220, 218, 213, 0.30);">

      <filters class="mb-3" @date-change="dateChanged" @toggle-filter="toggleFilter" />

      <b-collapse id="transaction-filter-collapse" accordion="my-accordion" @hidden="resetAdvanceFilters" @show="resetAdvanceFilters" style="padding: 25px;" class="common-border bg-white">
        <div v-if="isMasterMerchant" class="mb-4">
          <label class="">{{ $t('common.merchants') }}</label>
          <multiselect
            v-model="merchant_uuids"
            :options="merchantListDataFromStore"
            :placeholder="$t('common.search_merchants')"
            track-by="email_id"
            label="email_id"
            :searchable="true"
            :multiple="true"
          ></multiselect>
        </div>

        <div class="mb-4">
          <label class="">{{ $t('views.payments.transactions.payment_channels') }}</label>
          <multiselect
            v-model="filters.pmt_channel"
            :options="paymentChannelList"
            :placeholder="$t('common.search_payment_channels')"
            :searchable="true"
            :multiple="true"
          ></multiselect>
        </div>

        <div class="mb-4">
          <label>{{ $t('views.payment_channels.payment_methods') }}</label>
          <multiselect
            v-model="filters.pmt_method"
            :options="paymentMethodList"
            :placeholder="$t('common.search_payment_methods')"
            :searchable="true"
            :multiple="true"
          ></multiselect>
        </div>

        <div class="mb-4">
          <label>Transaction Status</label>
          <multiselect
            v-model="filters.status"
            :options="['Success', 'Initiated','Failed', 'Expired', 'Authorized', 'Error', 'Voided', 'Under Review']"
            :placeholder="$t('common.search_transaction_status')"
            :searchable="true"
            :multiple="true"
          ></multiselect>
        </div>

        <div class="d-flex justify-content-between">
          <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
          <div>
            <b-btn variant="primary" @click="toggleFilter" class="mr-2 bg-white text-dark cancel">Cancel</b-btn>
            <b-btn variant="primary" @click="setTransactionData" class="btn-padding">Apply</b-btn>
          </div>
        </div>
      </b-collapse>

      <search-by :options="searchByOptions" v-model="searchBy" @search-by-clicked="setTransactionData" class="my-3" />

      <b-table
        class="table-custom"
        :items="pageTransactions"
        :fields="fields"
        responsive="sm"
        @sort-changed="sortTransactions"
        sticky-header="500px"
        show-empty
        no-local-sorting
        :busy="isFetchingTransactions"
        :empty-text="$t('common.no-records')"
        hover
      >
        <template #table-busy>
            <div class="text-center">
                <b-spinner class="align-middle" :small="true"></b-spinner>
            </div>
        </template>

        <template v-slot:head()="data">
          <span>
            {{ $t(data.label) }}
            <img v-show="data.field.sortable && sorting_data.sort_by_key !== data.column" class="ml-2" src="@/assets/images/arrow-3.svg" alt="sort_up">
            <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'ASC'" class="ml-2" src="@/assets/images/sort_asc.svg" alt="sort_asc">
            <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'DESC'" class="ml-2" src="@/assets/images/sort_desc.svg" alt="sort_desc">
          </span>
        </template>

        <template v-slot:cell(created_at)="{ value }">
          {{ value | date }}
        </template>

        <template v-slot:cell(merchant_order_ref)="row">
          <router-link
            v-if="row.item.order_ref"
            :to="'/transactions/payments/payment-details?order_ref=' + row.item.order_ref +'&key='+row.item.merchant_key"
          >
            <span class="text-chai">{{row.value}}</span>
          </router-link>
        </template>

        <template v-slot:cell(status)="row">
          <div
            class="badge font-size-12"
            :class="{
                'badge-soft-danger': `${row.value}` === 'Failed' || `${row.value}` === 'Expired',
                'badge-soft-success': `${row.value}` === 'Success',
                'badge-soft-warning': `${row.value}` === 'Initiated',
                'badge-orange': row.value === 'Under Review'
              }"
          >{{ row.value }}</div>
          <div
            v-if="row.item.refund_completed"
            class="badge ml-1 badge-soft-success font-size-12"
          >{{ 'Refund Processed'}}</div>
        </template>

        <template v-slot:cell(amount)="{value, item}">
          {{ getFormattedNumber(value, item.currency) }}
        </template>

        <template v-slot:cell(channel_key)="row">
          <div class="badge font-size-12">
            <img :src="row.item.channel_logo" alt="user-image" class="mr-1" height="16" />
            <span class="align-middle">{{ row.value }}</span>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-between flex-wrap" style="gap: 10px;">
        <Per-page
          v-model="perPage"
          :currentPage="currentPage"
          :totalRows="totalRows"
          :tableRowsCount="pageTransactions.length"
          @input="setTransactionData"
        />
        <b-pagination
          class="my-auto"
          v-model="currentPage"
          :current-page="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @input="setTransactionData"
        ></b-pagination>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.xmx-table-date .today {
  color: #2a90e9;
}
</style>
<style lang="css" scoped>
::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
::v-deep .main-content {
    overflow: visible;
}
.page-details-1 td:first-child{
  height: 33px;
  color:#B3AFAF;
}

.page-details-2 td:first-child{
  height: 33px;
}
.badge {
  font-size: 95% !important;
}
.custom-btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  opacity: 1;
}
::v-deep .multiselect__placeholder {
  white-space: nowrap !important;
}
.badge-orange {
  color: #FA8234;
  background-color: #FA823414;
}
.down-btn button{
  height: 31px;
  position: relative;
  margin-left: 10px;
}
.btn-share {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  background: rgba(220, 218, 213, 0.30) !important;
}
::v-deep .toast {
  border-radius: 12px;
  border: 1px solid rgba(220, 218, 213, 0.30);
  background: #FFF;
  box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.30);
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #FC6B2D !important;
  border-color: #FC6B2D !important;
  background-color: rgba(252, 107, 45, 0.10) !important;
}

.page-detail-bg {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='344' height='241' viewBox='0 0 344 241' fill='none'%3E%3Cg filter='url(%23filter0_f_3847_40706)'%3E%3Cellipse cx='173' cy='72.6231' rx='36' ry='30.6446' fill='%23FC6B2D' fill-opacity='0.5'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_3847_40706' x='0' y='-95.0215' width='346' height='335.289' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='68.5' result='effect1_foregroundBlur_3847_40706'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") left top no-repeat, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='344' height='241' viewBox='0 0 344 241' fill='none'%3E%3Cg filter='url(%23filter0_f_3847_40706)'%3E%3Cellipse cx='173' cy='72.6231' rx='36' ry='30.6446' fill='%23FC6B2D' fill-opacity='0.5'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_3847_40706' x='0' y='-95.0215' width='346' height='335.289' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='68.5' result='effect1_foregroundBlur_3847_40706'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") right top no-repeat; 
}
</style>